/// <reference path="app.d.ts" />
angular.module('SearchUCFApp', []);

// Sevices
angular.module('SearchUCFApp').service('SearchService', SearchUCF.Services.SearchService);

// Controllers
angular.module('SearchUCFApp').controller('MainController', SearchUCF.Controllers.MainController);
angular.module('SearchUCFApp').controller('DirectoryController', SearchUCF.Controllers.DirectoryController);
angular.module('SearchUCFApp').controller('LocationController', SearchUCF.Controllers.LocationController);

// Directives
angular.module('SearchUCFApp').directive('directoryResults', SearchUCF.Directives.DirectoryResultsDirective);
angular.module('SearchUCFApp').directive('locationResults', SearchUCF.Directives.LocationResultsDirective);
angular.module('SearchUCFApp').directive('moreResults', SearchUCF.Directives.MoreResultsDirective);

// Filters
angular.module('SearchUCFApp').filter('formatLocation', SearchUCF.Filters.formatLocation);
angular.module('SearchUCFApp').filter('removeDashes', SearchUCF.Filters.removeDashes);
angular.module('SearchUCFApp').filter('googleMapDirections', SearchUCF.Filters.googleMapDirections);
angular.module('SearchUCFApp').filter('numToAlpha', SearchUCF.Filters.numToAlpha);

var $googleResultsContainer;

// Load Google search from hash tag
var searchCallback = function () {
    $googleResultsContainer = $('#___gcse_1');
    $('.gsc-input').attr('placeholder', SearchUCF.Utils.getPlaceholder());
    if (window.location.hash) {
        var paramObj = SearchUCF.Utils.GetURLParams(window.location.hash);
        if (paramObj.q) {
            google.search.cse.element.getElement('two-column').execute(paramObj.q);
        }
    }
};

window.__gcse = {
    callback: searchCallback
};

// Init Google Custom Search
(function () {
    var cx = window.CONFIG.GOOGLE_SEARCH_API;
    var gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
})();

(function () {
    var $searchContainer;
    var $resultsContainer;

    function setupEventHandlers() {
        $resultsContainer.on('click', '.gs-spelling', function (e) {
            window.location.hash = '?q=' + $searchContainer.find('input.gsc-input').val();
        });

        $searchContainer.on('keyup', 'input.gsc-input', function (e) {
            if ($(e.target).val().length > 2) {
                window.location.hash = '?q=' + $searchContainer.find('input.gsc-input').val();
            } else {
                window.location.hash = '';
            }
        });
    }

    function util() {
        $('#ucfhb a[href="#"]').on('click', function (e) {
            e.preventDefault();
        });
    }

    function searchInit() {
        $searchContainer = $('.search-container');
        $resultsContainer = $('.results-container');
        setupEventHandlers();
        util();
    }

    $(searchInit);
})();
