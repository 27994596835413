/// <reference path="../app.d.ts" />
var SearchUCF;
(function (SearchUCF) {
    (function (Directives) {
        function DirectoryResultsDirective() {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'static/templates/directory-results.html'
            };
        }
        Directives.DirectoryResultsDirective = DirectoryResultsDirective;

        function LocationResultsDirective() {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'static/templates/location-results.html'
            };
        }
        Directives.LocationResultsDirective = LocationResultsDirective;

        function MoreResultsDirective() {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    link: '@'
                },
                templateUrl: 'static/templates/more-results.html'
            };
        }
        Directives.MoreResultsDirective = MoreResultsDirective;
    })(SearchUCF.Directives || (SearchUCF.Directives = {}));
    var Directives = SearchUCF.Directives;
})(SearchUCF || (SearchUCF = {}));
