var __extends = this.__extends || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    __.prototype = b.prototype;
    d.prototype = new __();
};
var SearchUCF;
(function (SearchUCF) {
    /// <reference path="../app.d.ts" />
    (function (Controllers) {
        var DirectoryController = (function (_super) {
            __extends(DirectoryController, _super);
            function DirectoryController($scope, $location, searchService) {
                _super.call(this, $scope, $location, searchService);
            }
            DirectoryController.prototype.GetSearchResults = function () {
                var _this = this;
                _super.prototype.GetSearchResults.call(this);

                this.searchService.GetDirectoryResults(this.searchQuery, function (data, status) {
                    _this.SuccessHandler(data, status);
                }, function (error, code) {
                    _this.ErrorHandler(error, code);
                });
            };

            DirectoryController.prototype.SuccessHandler = function (data, status) {
                _super.prototype.SuccessHandler.call(this, data, status);
                this.results = data.results;
                this.resultCount = data.resultCount;
            };
            return DirectoryController;
        })(SearchUCF.Controllers.BaseController);
        Controllers.DirectoryController = DirectoryController;
    })(SearchUCF.Controllers || (SearchUCF.Controllers = {}));
    var Controllers = SearchUCF.Controllers;
})(SearchUCF || (SearchUCF = {}));
