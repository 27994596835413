var __extends = this.__extends || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    __.prototype = b.prototype;
    d.prototype = new __();
};
var SearchUCF;
(function (SearchUCF) {
    /// <reference path="../app.d.ts" />
    (function (Controllers) {
        var LocationController = (function (_super) {
            __extends(LocationController, _super);
            function LocationController($scope, $location, searchService) {
                _super.call(this, $scope, $location, searchService);
                this.selectedIndex = null;
            }
            LocationController.prototype.GetSearchResults = function () {
                var _this = this;
                _super.prototype.GetSearchResults.call(this);

                this.searchService.GetLocationResults(this.searchQuery, function (data, status) {
                    _this.SuccessHandler(data, status);
                }, function (error, code) {
                    _this.ErrorHandler(error, code);
                });
            };

            LocationController.prototype.SuccessHandler = function (data, status) {
                _super.prototype.SuccessHandler.call(this, data, status);
                if (data.results) {
                    this.results = SearchUCF.Utils.filterLocationResults(data.results.locations);
                    this.resultCount = data.results.locations.length;
                }
            };

            LocationController.prototype.onResultClick = function (point, index) {
                this.selectedIndex = this.selectedIndex !== index ? index : null;
            };
            LocationController.$inject = ['$scope', '$location', 'SearchService'];
            return LocationController;
        })(SearchUCF.Controllers.BaseController);
        Controllers.LocationController = LocationController;
    })(SearchUCF.Controllers || (SearchUCF.Controllers = {}));
    var Controllers = SearchUCF.Controllers;
})(SearchUCF || (SearchUCF = {}));
