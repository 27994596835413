/// <reference path="app.d.ts" />
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

var SearchUCF;
(function (SearchUCF) {
    var Utils = (function () {
        function Utils() {
        }
        Utils.GetURLParams = function (paramString) {
            // Split by '&' before using decodeURIComponent to make sure
            // encoded ampersands aren't treated as split delimiters
            var variables = paramString.split('&'), retval = {};

            for (var i = 0; i < variables.length; i++) {
                var paramName = decodeURIComponent(variables[i].replace(/^(#\?|\?#|\?|#)/, '')).split('=');
                var key = SearchUCF.Utils.CorrectParamCase(paramName[0]);
                if (key !== null) {
                    retval[key] = paramName[1];
                }
            }

            return retval;
        };

        Utils.filterLocationResults = function (locationArray) {
            return locationArray.filter(function (location) {
                return location.object_type === "Building" || location.object_type === "Location" || location.object_type === "DiningLocation";
            });
        };

        Utils.getPlaceholder = function () {
            return SearchUCF.Config.PLACEHOLDERS[SearchUCF.Utils.GetRandom(0, SearchUCF.Config.PLACEHOLDERS.length - 1)];
        };

        Utils.GetRandom = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        Utils.CorrectParamCase = function (key) {
            for (var i in SearchUCF.Config.PARAM_WHITELIST) {
                var param = SearchUCF.Config.PARAM_WHITELIST[i];
                if (key.toLowerCase() === param.toLowerCase()) {
                    return param;
                }
            }
        };
        return Utils;
    })();
    SearchUCF.Utils = Utils;
})(SearchUCF || (SearchUCF = {}));
