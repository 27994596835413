var SearchUCF;
(function (SearchUCF) {
    /// <reference path="../app.d.ts" />
    (function (Filters) {
        function removeDashes() {
            return function (text) {
                if (text) {
                    return text.replace(/-/g, '');
                } else {
                    return '';
                }
            };
        }
        Filters.removeDashes = removeDashes;

        function googleMapDirections() {
            return function (mapPoint) {
                if (mapPoint) {
                    return 'https://www.google.com/maps/dir/Current+Location/' + mapPoint[0] + ',' + mapPoint[1];
                } else {
                    return '';
                }
            };
        }
        Filters.googleMapDirections = googleMapDirections;

        function upperLetterFromIndex() {
            return function (index) {
                if (index >= 0) {
                    return String.fromCharCode(65 + index);
                } else {
                    return '';
                }
            };
        }
        Filters.upperLetterFromIndex = upperLetterFromIndex;

        function numToAlpha() {
            return function (i) {
                return i >= 0 && i <= 26 ? String.fromCharCode(i + 65) : null;
            };
        }
        Filters.numToAlpha = numToAlpha;
    })(SearchUCF.Filters || (SearchUCF.Filters = {}));
    var Filters = SearchUCF.Filters;
})(SearchUCF || (SearchUCF = {}));
