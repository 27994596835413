/// <reference path="app.d.ts" />
var SearchUCF;
(function (SearchUCF) {
    var Config = (function () {
        function Config() {
        }
        Config.GOOGLE_SEARCH_API = window.CONFIG.GOOGLE_SEARCH_API;
        Config.SEARCH_SERVICE_URL = window.CONFIG.SEARCH_SERVICE_URL;
        Config.LOCATIONS_SEARCH_URL = window.CONFIG.LOCATIONS_SEARCH_URL;
        Config.LOCATIONS_ROOT_URL = window.CONFIG.LOCATIONS_ROOT_URL;
        Config.SEARCH_SERVICE_RESULT_LIMIT = window.CONFIG.SEARCH_SERVICE_RESULT_LIMIT;
        Config.PLACEHOLDERS = window.CONFIG.PLACEHOLDERS;
        Config.PARAM_WHITELIST = window.CONFIG.PARAM_WHITELIST;
        return Config;
    })();
    SearchUCF.Config = Config;
})(SearchUCF || (SearchUCF = {}));
