var SearchUCF;
(function (SearchUCF) {
    /// <reference path="../app.d.ts" />
    (function (Controllers) {
        var BaseController = (function () {
            function BaseController($scope, $location, searchService) {
                var _this = this;
                this.scope = $scope;
                this.location = $location;
                this.searchService = searchService;

                if (this.location.search().q !== undefined) {
                    this.GetSearchResults();
                }

                this.scope.$on('$locationChangeSuccess', function () {
                    if (_this.timeoutId)
                        window.clearTimeout(_this.timeoutId);
                    _this.timeoutId = window.setTimeout(function () {
                        _this.GetSearchResults();
                    }, 500);
                });
            }
            BaseController.prototype.GetSearchResults = function () {
                this.searchQuery = this.location.search().q;
            };

            BaseController.prototype.SuccessHandler = function (data, status) {
                if (status !== 200) {
                    this.error = true;
                    return;
                }
            };

            BaseController.prototype.ErrorHandler = function (error, code) {
                this.error = true;
            };

            BaseController.prototype.Reset = function () {
                this.results = null;
                this.resultCount = undefined;
                this.error = false;
            };
            BaseController.$inject = ['$scope', '$location', 'SearchService'];
            return BaseController;
        })();
        Controllers.BaseController = BaseController;
    })(SearchUCF.Controllers || (SearchUCF.Controllers = {}));
    var Controllers = SearchUCF.Controllers;
})(SearchUCF || (SearchUCF = {}));
