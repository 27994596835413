var SearchUCF;
(function (SearchUCF) {
    /// <reference path="../app.d.ts" />
    (function (Services) {
        var Config = SearchUCF.Config;

        var SearchService = (function () {
            function SearchService($http, $location) {
                this.http = $http;
                this.location = $location;
            }
            SearchService.prototype.GetSearchResults = function (query) {
                this.http.get(query.url, { params: query.params }).success(function (data, status) {
                    query.success(data, status);
                }).error(function (error, code) {
                    query.error(error, code);
                });
            };

            SearchService.prototype.UpdateLocation = function (search) {
                var args = this.location.search();
                if (search !== this.previousQuery) {
                    args.start = null;
                }
                for (var arg in args) {
                    if (arg === 'cx' || arg === 'key') {
                        args[arg] = null;
                    }
                }

                this.location.search(args);

                return args;
            };

            SearchService.prototype.GetDirectoryResults = function (search, successCallback, errorCallback) {
                var args = {
                    search: search,
                    limit: Config.SEARCH_SERVICE_RESULT_LIMIT,
                    type: null
                };

                this.GetSearchResults({
                    params: args,
                    success: successCallback,
                    error: errorCallback,
                    url: Config.SEARCH_SERVICE_URL
                });
            };

            SearchService.prototype.GetLocationResults = function (search, successCallback, errorCallback) {
                var args = {
                    q: search,
                    extended: true,
                    type: null
                };

                this.GetSearchResults({
                    params: args,
                    success: successCallback,
                    error: errorCallback,
                    url: Config.LOCATIONS_SEARCH_URL
                });
            };
            SearchService.$inject = ['$http', '$location'];
            return SearchService;
        })();
        Services.SearchService = SearchService;
    })(SearchUCF.Services || (SearchUCF.Services = {}));
    var Services = SearchUCF.Services;
})(SearchUCF || (SearchUCF = {}));
