var SearchUCF;
(function (SearchUCF) {
    /// <reference path="../app.d.ts" />
    (function (Controllers) {
        // Search Controller
        var MainController = (function () {
            function MainController($scope, $location, googleCtl, dirCtl, locCtl) {
                this.scope = $scope;
                this.location = $location;
                if (window.location.hash === '') {
                    var params = SearchUCF.Utils.GetURLParams(window.location.search);
                    this.location.search(params);
                }
                this.scope.$watch('mainCtl.searchQuery', this.HandleInput);

                if (this.location.url()) {
                    var args = this.location.search();
                    if (args) {
                        this.searchQuery = args.q;
                        this.UpdateLocation(args.q);
                    }
                }

                this.OnHashChangeHandler();
            }
            MainController.prototype.OnHashChangeHandler = function () {
                this.scope.$watch(function () {
                    return location.hash;
                }, function (value) {
                    if (value.length > 2) {
                        var paramObj = SearchUCF.Utils.GetURLParams(window.location.hash);
                        if (paramObj.q) {
                            google.search.cse.element.getElement('two-column').execute(paramObj.q);
                            if ($googleResultsContainer !== undefined)
                                $googleResultsContainer.show();
                        }
                    } else {
                        window.location.hash = '';
                        if ($googleResultsContainer !== undefined)
                            $googleResultsContainer.hide();
                    }
                });
            };

            MainController.prototype.UpdateLocation = function (query) {
                this.location.search('q', query);
            };

            MainController.prototype.HandleInput = function (newValue, oldValue, scope) {
                var controller = scope.mainCtl;
                controller.UpdateLocation(newValue);
            };
            MainController.$inject = ['$scope', '$location'];
            return MainController;
        })();
        Controllers.MainController = MainController;
    })(SearchUCF.Controllers || (SearchUCF.Controllers = {}));
    var Controllers = SearchUCF.Controllers;
})(SearchUCF || (SearchUCF = {}));
